<template>
    <div id="public-offer">

        <mainheader></mainheader>
        <div class="d-flex justify-content-center mb-3">
            <h1>{{ translation.translate('LandingPageHome', 'public-offer') }}</h1>
        </div>
        <div class="d-flex justify-content-center" >
            
            <object style="max-width: 800px; width: 100%; height: 80vh;">
                <iframe src="/resource/public-offer.pdf#toolbar=0" width="100%" height="100%" frameborder="0"></iframe>
            </object>
    </div>
        <mainfooter></mainfooter>
    </div>
</template>

<script>
import mainheader from "./mainheader.vue";
import translate from "../../translation/translate";
import mainfooter from "./mainfooter.vue";
export default {
    name:"public-offer",
    data() {
    return {
        translation: translate,
      };
    },
    components: {
        mainheader,
        mainfooter
    }
}
</script>